import {
  IonPage,
  IonContent,
  IonIcon,
  IonLabel,
  useIonAlert,
  IonHeader,
  IonToolbar,
  IonButton,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonItem,
  IonThumbnail,
  IonCard,
  IonTitle,
  IonSegment,
  IonSegmentButton,
  IonCardContent,
  IonText,
  IonInput,
  IonCheckbox,
  IonImg,
  IonFab,
  IonFabButton,
  useIonLoading,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonRadioGroup,
  IonRadio,
  IonCardHeader,
  IonBadge,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RestApi } from "../../services/Api";
import { addCircleOutline, arrowBackOutline, calendar, locateOutline, saveOutline, storefrontSharp } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { CustomerProfile } from "../../models/Modal";
import { loadList } from "../../models/Data";
import SelectOption from "../input/SelectOption";
import { GoogleMap,InfoWindow,Marker,useLoadScript} from "@react-google-maps/api";
import { Capacitor, Plugins } from "@capacitor/core";
import CheckPermissions from "../../services/Permission";
import { Device } from "@capacitor/device";
import { useAuth } from "../../data/sessions/Auth";
import { Geolocation } from "@capacitor/geolocation";
import { BackgroundGeolocation } from "../../App";
import { CameraSource } from "@capacitor/camera";
import { usePhotoGallery } from "../../services/Camera";
import InputDate from "../input/Date";
import { getNameDate, getRoleMenu } from "../../services/Library";
import { useMaskito } from "@maskito/react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import "./Form.scss";

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
};

const Form: React.FC<CustomerProfile> = (props) => {
  const { t } = useTranslation(["general"]);
  const [alert] = useIonAlert();
  const { logOut } = useAuth()!;
  const [presentLoading, dismissLoading] = useIonLoading();
  const {deletePhoto, photos, takePhoto } = usePhotoGallery();
  const [customerType, setCustomerType] = useState<any>([]);
  const [showModalDate, setShowModalDate] = useState<boolean>(false);
  const [nameDate, setNameDate] = useState<any>();
  const { handleSubmit } = useForm();
  const [customer, setCustomer] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [subArea, setSubArea] = useState<any>([]);
  const [state, setState] = useState<any>([]);
  const [country, setCountry] = useState<any>([]);
  const [title, setTitle] = useState<any>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showModalSelectOptionCustomerId, setShowModalSelectOptionCustomerId] = useState<boolean>(false);
  const [showModalSelectOptionCity, setShowModalSelectOptionCity] = useState<boolean>(false);
  const [showModalSelectOptionState, setShowModalSelectOptionState] = useState<boolean>(false);
  const [showModalSelectOptionSubArea, setShowModalSelectOptionSubArea] = useState<boolean>(false);
  const [segment, setSegment] = useState<string>("information");
  const [subAreaName, setSubAreaName] = useState<string>("");
  const [onLoadInfo, setOnLoadInfo] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<any>([]);
  const [assessment, setAssessment] = useState<any>([]);
  const [setting, setSetting] = useState<any>([]);
  const [indexAssessment, setindexAssessment] = useState<string>('');
  const [segmentImageCurrent, setSegmentImageCurrent] = useState<number>(0);
  const [countTotal, setCountTotal] = useState<number>(0);
  const user = useSelector((state: any) => state.user.value);
  const getRole = getRoleMenu(user.role_menu, "customer_profile");
  const [segmentImage, setSegmentImage] = useState<any>([
    {
      active: true,
      customer: "customer_identity_card_image",
      customerProfile: "customer_profile_identity_card_image",
      photo: null
    },
    {
      active: false,
      customer: "customer_image1",
      customerProfile: "customer_profile_image1",
      photo: null
    },
    {
      active: false,
      customer: "customer_image2",
      customerProfile: "customer_profile_image2",
      photo: null
    },
    {
      active: false,
      customer: "customer_image3",
      customerProfile: "customer_profile_image3",
      photo: null
    },
    {
      active: false,
      customer: "customer_image4",
      customerProfile: "customer_profile_image4",
      photo: null
    },
  ]);
  const [selectCustomer, setSelectCustomer] = useState<any>({
    customer_is_active: 1,
    customer_name: null,
    customer_title: null,
    customer_customer_type_id: null,
    customer_subarea_id: null,
    customer_remark: null,
    customer_address1: null,
    customer_address2: null,
    customer_ship_address1: null,
    customer_ship_address2: null,
    customer_city: null,
    customer_state: null,
    customer_country: "Indonesia",
    customer_zipcode: null,
    customer_phone: null,
    customer_fax: null,
    customer_url: null,
    customer_email: null,
    customer_tax_reg_no: 0,
    customer_tax_name: null,
    customer_tax_number: null,
    customer_contact_name: null,
    customer_contact_title: null,
    customer_contact_mobile: null,
    customer_contact_email: null,
    customer_contact_hobby: null,
    customer_contact_address: null,
    customer_contact_birthday: null,
    customer_identity_card_name: null,
    customer_identity_card_number: null,
    customer_identity_card_image: null,
    customer_lat: null,
    customer_lng: null,
    customer_image1: null,
    customer_image2: null,
    customer_image3: null,
    customer_image4: null,
  });
  const [customerProfile, setCustomerProfile] = useState<any>({
    customer_profile_customer_id: "C00000",
    customer_profile_is_active: 1,
    customer_profile_name: null,
    customer_profile_title: null,
    customer_profile_customer_type_id: null,
    customer_profile_subarea_id: null,
    customer_profile_remark: null,
    customer_profile_address1: null,
    customer_profile_address2: null,
    customer_profile_ship_address1: null,
    customer_profile_ship_address2: null,
    customer_profile_city: null,
    customer_profile_state: null,
    customer_profile_country: "Indonesia",
    customer_profile_zipcode: null,
    customer_profile_phone: null,
    customer_profile_fax: null,
    customer_profile_url: null,
    customer_profile_email: null,
    customer_profile_tax_reg_no: 0,
    customer_profile_tax_number: null,
    customer_profile_contact_name: null,
    customer_profile_contact_title: null,
    customer_profile_contact_mobile: null,
    customer_profile_contact_email: null,
    customer_profile_contact_hobby: null,
    customer_profile_contact_address: null,
    customer_profile_contact_birthday: new Date().toISOString().slice(0, 10),
    customer_profile_identity_card_name: null,
    customer_profile_identity_card_number: null,
    customer_profile_identity_card_image: null,
    customer_profile_lat: null,
    customer_profile_lng: null,
    customer_profile_image1: null,
    customer_profile_image2: null,
    customer_profile_image3: null,
    customer_profile_image4: null,
    customer_profile_visit_note: null,
    customer_profile_visit_date: new Date().toISOString().slice(0, 10),
  });
  
  const taxMask = useMaskito({
    options: {
      mask: [
        ...Array(2).fill(/\d/),
        ".",
        ...Array(3).fill(/\d/),
        ".",
        ...Array(3).fill(/\d/),
        ".",
        ...Array(1).fill(/\d/),
        "-",
        ...Array(3).fill(/\d/),
        ".",
        ...Array(3).fill(/\d/),
      ],
    },
  });

  const identityCardMask = useMaskito({
    options: {
      mask: [
        ...Array(16).fill(/\d/),
      ],
    },
  });

  const massageAlert = async (status: string, body: string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [
        { text: "Ya", cssClass: "alert-button-confirm", role: "confirm" },
      ],
    });
  };

  const guessLocation = (timeout: any) => {
    return new Promise(function (resolve) {
      let id: any;
      let lastLocation: any;
      BackgroundGeolocation.addWatcher(
        {
          requestPermissions: true,
          stale: false,
          backgroundMessage: "Cancel to prevent battery drain.",
          backgroundTitle: "Tracking You.",
        },
        async function callback(location) {
          if (location?.simulated === true) {
            await mockLog();
            resolve(lastLocation);
            BackgroundGeolocation.removeWatcher({ id });
            return;
          }
          lastLocation = location;
        }
      )
        .then(function retain_callback_id(the_id) {
          id = the_id;
        })
        .catch(() => {
          massageAlert("error", "Aktifkan GPS!!!!!");
        });
      setTimeout(function () {
        resolve(lastLocation);
        BackgroundGeolocation.removeWatcher({ id });
      }, timeout);
    });
  };

  const handleModalDateClose = async (inputDate: any) => {
    setShowModalDate(false);
    if (inputDate) {
      setCustomerProfile((prevState: any) => ({
        ...prevState,
        [nameDate]: new Date(inputDate).toISOString().slice(0, 10),
      }));
    }
  };

  const currentLocation = async () => {
    if (Capacitor.isNative) {
      CheckPermissions.requestFineLocation();
      guessLocation(50000);
    }
    const location = await Geolocation.getCurrentPosition({
      enableHighAccuracy: true,
    });
    setCoordinates(location);
  };
  
  const mockLog = async () => {
    const info = await Device.getId();
    const application = await Device.getInfo();
    await RestApi("user/mocklog", "post", {
      imei: info.identifier,
      application: application,
    })
      .then((res: any) => {
        logOut();
        props.onDismiss();
        massageAlert("error", "Anda Menggunakan Fake GPS");
      })
      .catch((error: any) => {
        logOut();
        props.onDismiss();
        massageAlert("error", "Anda Menggunakan Fake GPS!!!!!");
      });
  };

  const setLocation = () => {
    currentLocation();
    setSelectCustomer((prevState: any) => ({
      ...prevState,
      customer_lat: null,
      customer_lng: null,
    }));
    setCustomerProfile((prevState: any) => ({
      ...prevState,
      customer_profile_lat: coordinates.coords.latitude,
      customer_profile_lng: coordinates.coords.longitude,
    }));
  };

  const getForm = async (page: string) => {
    await RestApi(`${page}`, "get", {}, "application/json")
      .then((res: any) => {
        if (res.data.code === 200) {
          setCustomer(res.data.data.customer);
          setCustomerType(res.data.data.customerType);
          setCity(res.data.data.city);
          setSubArea(res.data.data.subArea);
          setState(res.data.data.state);
          setTitle(res.data.data.title);
          setAssessment(res.data.data.assessment);
          setSetting(res.data.data.setting);
          setCountry(res.data.data.country);
          if (props.type === 'edit') {
            setCustomerProfile(res.data.data.customerProfile);
            let total = 0;
            setAssessment((prevState: any) => {
              // Loop over your list
              prevState.forEach((val: any) => {
                let countSubTotal = 0;
                val.assessment.forEach((valList: any) => {
                  let getSelected = setValRadioAssessment(
                    valList.assessment_value
                  );
                  valList.total =  Number(getSelected?.weight ?? 0) * Number(valList.assessment_weight);
                  countSubTotal += valList.total;
                });
                val.countSubTotal = countSubTotal;
                total += countSubTotal;
              });
              return prevState;
            });
            let getSubArea = res.data.data.subArea.find((val: any) => val.subarea_id === res.data.data.customerProfile.customer_profile_subarea_id);
            setSubAreaName(getSubArea?.subarea_name ?? "");
            setCountTotal(total);
          }
          setLoaded(true);
        } else {
          massageAlert("error", res.data.message);
          props.onDismiss();
        }
      })
      .catch((err: any) => {
        props.onDismiss();
        massageAlert("error", err.message);
      });
  };

  const postCustomerProfile = async (e:any) => {
    const FormData = global.FormData;
    let formData = new FormData();
    for (const key in customerProfile) {
      if (customerProfile.hasOwnProperty(key)) {
        let value = customerProfile[key];
        if (value) {
          formData.append(key, value);
        }
      }
    }
    segmentImage.forEach((item: any) => {
      if (item.photo) {
        formData.append(`${item.customerProfile}`, item.photo.blobData);
      }
    });
    formData.append(`customer_assessment`, JSON.stringify(assessment));
    await presentLoading({ message: t('loading') });
    await RestApi(
      `customer-profile${props.type === "edit" ? `/${customerProfile.customer_profile_id}` : ``}`,
      `post`,
      formData,
      `application/x-www-form-urlencoded`
    )
    .then(async (res: any) => {
      if (res.data.code === 200) {
        if (photos.length) {
          photos.forEach((val: any) => {
            if (val) {
              deletePhoto(val);
            }
          });
        }
        props.onDismiss();
        massageAlert("success", res.data.message);
        dismissLoading();
      } else {
        massageAlert("error", res.data.message);
        dismissLoading();
      }
    })
    .catch((error: any) => {
      massageAlert("error", error.message);
      dismissLoading();
    });
  };

  const customerSelectionChanged = (idCustomer: string[]) => {
    segmentImage.forEach((item: any) => {
      item.photo = null
    });
    let getCustomer = customer.find(
      (val: any) => val.customer_id === idCustomer
    ); 
    setSegmentImage(segmentImage);
    setSelectCustomer(getCustomer);
    setShowModalSelectOptionCustomerId(!showModalSelectOptionCustomerId);
    if (getCustomer) {
      let getSubArea = subArea.find((val: any) => val.subarea_id === getCustomer.customer_subarea_id);
      setSubAreaName(getSubArea?.subarea_name ?? "");
      setCustomerProfile((prevState: any) => ({
        ...prevState,
        customer_profile_customer_id: getCustomer.customer_id,
        customer_profile_is_active: getCustomer.customer_is_active,
        customer_profile_name: getCustomer.customer_name,
        customer_profile_title: getCustomer.customer_title,
        customer_profile_customer_type_id: getCustomer.customer_customer_type_id,
        customer_profile_subarea_id: getCustomer.customer_subarea_id,
        customer_profile_remark: getCustomer.customer_remark,
        customer_profile_address1: getCustomer.customer_address1,
        customer_profile_address2: getCustomer.customer_address2,
        customer_profile_ship_address1: getCustomer.customer_ship_address1,
        customer_profile_ship_address2: getCustomer.customer_ship_address2,
        customer_profile_city: getCustomer.customer_city,
        customer_profile_state: getCustomer.customer_state,
        customer_profile_country: getCustomer.customer_country,
        customer_profile_zipcode: getCustomer.customer_zipcode,
        customer_profile_phone: getCustomer.customer_phone,
        customer_profile_fax: getCustomer.customer_fax,
        customer_profile_url: getCustomer.customer_url,
        customer_profile_email: getCustomer.customer_email,
        customer_profile_tax_reg_no: getCustomer.customer_tax_reg_no,
        customer_profile_tax_name: getCustomer.customer_tax_name,
        customer_profile_tax_number: getCustomer.customer_tax_number,
        customer_profile_contact_name: getCustomer.customer_contact_name,
        customer_profile_contact_title: getCustomer.customer_contact_title,
        customer_profile_contact_mobile: getCustomer.customer_contact_mobile,
        customer_profile_contact_email: getCustomer.customer_contact_email,
        customer_profile_contact_hobby: getCustomer.customer_contact_hobby,
        customer_profile_contact_address: getCustomer.customer_contact_address,
        customer_profile_contact_birthday: getCustomer.customer_contact_birthday,
        customer_profile_identity_card_name: getCustomer.customer_identity_card_name,
        customer_profile_identity_card_number: getCustomer.customer_identity_card_number,
        customer_profile_identity_card_image: getCustomer.customer_identity_card_image,
        customer_profile_lat: getCustomer.customer_lat,
        customer_profile_lng: getCustomer.customer_lng,
        customer_profile_image1: getCustomer.customer_image1,
        customer_profile_image2: getCustomer.customer_image2,
        customer_profile_image3: getCustomer.customer_image3,
        customer_profile_image4: getCustomer.customer_image4,
      }));
    }else{
      setLocation()
    }
  };

  const citySelectionChanged = (idCity: string[]) => {
    let getCity = city.find((val: any) => val.city_id === idCity); 
    setSelectCustomer((prevState: any) => ({
      ...prevState,
      customer_city: null,
    }));
    setCustomerProfile((prevState: any) => ({
      ...prevState,
      customer_profile_city: getCity?.city_name??null,
    }));
  };

  const stateSelectionChanged = (idState: string[]) => {
    let getState = state.find((val: any) => val.state_id === idState);
    setSelectCustomer((prevState: any) => ({
      ...prevState,
      customer_state: null,
    }));
    setCustomerProfile((prevState: any) => ({
      ...prevState,
      customer_profile_state: getState?.state_name??null,
    }));
  };

  const subAreaSelectionChanged = (idSubarea: string[]) => {
    let getSubArea = subArea.find((val: any) => val.subarea_id === idSubarea);
    setSubAreaName(getSubArea?.subarea_name ?? "");
    setSelectCustomer((prevState: any) => ({
      ...prevState,
      customer_subarea_id: null,
    }));
    setCustomerProfile((prevState: any) => ({
      ...prevState,
      customer_profile_subarea_id: idSubarea,
    }));
  };

  const selectImageSource = async () => {
    if (
      selectCustomer[segmentImage[segmentImageCurrent].customer] ||
      customerProfile[segmentImage[segmentImageCurrent].customerProfile] ||
      segmentImage[segmentImageCurrent].photo
    ) {
      alert({
        message: "Yakin ingin menghapus foto ini? ",
        buttons: [
          { text: "Tidak", cssClass: "alert-button-cancel", role: "cancel" },
          { text: "Ya", cssClass: "alert-button-confirm", role: "confirm" },
        ],
        backdropDismiss: false,
        onDidDismiss: async (e) => {
          if (e.detail.role === "confirm") {
            let photo = await takePhoto(CameraSource.Camera);
            if (photo) {
              selectCustomer[segmentImage[segmentImageCurrent].customer] = null;
              customerProfile[segmentImage[segmentImageCurrent].customerProfile] = null;
              segmentImage[segmentImageCurrent].photo = photo;
              setSegmentImage(segmentImage);
              setCustomerProfile(customerProfile);
              setSelectCustomer(selectCustomer);
            }
          }
        },
      });
    } else {
      let photo = await takePhoto(CameraSource.Camera);
      if (photo) {
        selectCustomer[segmentImage[segmentImageCurrent].customer] = null;
        customerProfile[segmentImage[segmentImageCurrent].customerProfile] = null;
        segmentImage[segmentImageCurrent].photo = photo;
        setSegmentImage(segmentImage);
        setCustomerProfile(customerProfile);
        setSelectCustomer(selectCustomer);
      }
    }
  };

  const changeImageSegment = async (name: string) => {
    segmentImage.forEach((item:any,key:number) => {
      if (item.customer === name) {
        setSegmentImageCurrent(key);
        item.active = true
      } else {
        item.active = false
      }
    });
    setSegmentImage(segmentImage);
  }

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAP || "",
  });

  useConstructor(async () => {
    if (Capacitor.isNative) {
      Plugins.App.addListener("backButton", (e: any) => {
        if (photos.length) {
          photos.forEach((val: any) => {
            if (val) {
              deletePhoto(val);
            }
          });
        }
      });
    }
    currentLocation();
    if (props.type === "create") {
      getForm(`customer-profile/create`);
    } else {
      getForm(`customer-profile/${props.idCustomerProfile}/edit`);
    }
  });

  const setValRadioAssessment = (val:any) => {
    return val.find((item: any) => item?.selected === true);
  }
  
  useEffect(() => {}, [
    selectCustomer,
    customerProfile,
    segmentImageCurrent,
    segmentImage,
    photos,
    assessment,
    countTotal,
  ]);

  return (
    <IonPage>
      <IonHeader className="toolbar-form">
        <IonToolbar className="ion-no-padding">
          <IonButton
            color="light"
            slot="start"
            fill="clear"
            onClick={() => {
              if (photos.length) {
                photos.forEach((val: any) => {
                  if (val) {
                    deletePhoto(val);
                  }
                });
              }
              props.onDismiss();
            }}
          >
            <IonIcon color="light" slot="start" src={arrowBackOutline} />
          </IonButton>
          <IonLabel color="light" className="ion-text-center ion-no-padding">
            <h2>{t(props.type) + " " + t("module.customer-profile")}</h2>
          </IonLabel>
          <IonButton fill="clear" slot="end"></IonButton>
        </IonToolbar>
      </IonHeader>
      {loaded ? (
        <>
          <IonContent className="form-content">
            <form onSubmit={handleSubmit(postCustomerProfile)}>
              <IonCard className="ion-no-padding ">
                <IonItem className="ion-no-padding ion-no-margin">
                  <IonTitle className="ion-padding-start ion-no-margin">
                    <strong>Survey {t("module.customer-profile")}</strong>
                  </IonTitle>
                </IonItem>
                <IonItem className="ion-no-padding ion-no-margin">
                  <IonText className="ion-padding-start ion-no-margin">
                    <span>{t("date")} Survey : </span>
                  </IonText>
                  <IonButton
                    color="light"
                    slot="end"
                    fill="clear"
                    disabled={
                      getRole?.role_menu_edit === 1 &&
                      getRole?.role_menu_add === 1 &&
                      getRole?.role_menu_delete === 1
                        ? false
                        : true
                    }
                    onClick={() => {
                      setShowModalDate(true);
                      setNameDate(`customer_profile_visit_date`);
                    }}
                  >
                    <IonText color={"dark"}>
                      {getNameDate(customerProfile.customer_profile_visit_date)}
                      <IonIcon color="danger" src={calendar} />
                    </IonText>
                  </IonButton>
                </IonItem>
                <IonItem lines="none">
                  <IonTextarea
                    labelPlacement="floating"
                    placeholder={t("note")}
                    label={t("note")}
                    required={true}
                    name="customer_profile_visit_note"
                    onIonInput={(e: any) =>
                      setCustomerProfile((prevState: any) => ({
                        ...prevState,
                        customer_profile_visit_note: e.detail.value,
                      }))
                    }
                    value={customerProfile.customer_profile_visit_note}
                  ></IonTextarea>
                </IonItem>
              </IonCard>
              <IonCard className="ion-no-padding">
                <IonItem className="ion-no-padding ion-no-margin">
                  <IonTitle className="ion-padding-start ion-no-margin">
                    <strong>CPF</strong>
                  </IonTitle>
                  <IonButton
                    disabled={props.type === "edit" ? true : false}
                    onClick={() => {
                      setShowModalSelectOptionCustomerId(true);
                    }}
                    color={"danger"}
                    className="ion-no-padding ion-no-margin"
                  >
                    <IonTitle>
                      <IonIcon
                        size="medium"
                        className="ion-no-padding ion-no-margin"
                        src={addCircleOutline}
                      ></IonIcon>
                      <strong>
                        {customerProfile.customer_profile_customer_id}
                      </strong>
                    </IonTitle>
                  </IonButton>
                </IonItem>
                <IonSegment
                  value={segment}
                  scrollable={true}
                  className="ion-no-padding ion-no-margin"
                >
                  <IonSegmentButton
                    value="information"
                    onClick={() => {
                      setSegment("information");
                    }}
                  >
                    <IonLabel>{t("information")}</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="contact"
                    onClick={() => {
                      setSegment("contact");
                    }}
                  >
                    <IonLabel>{t("contact")}</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="detail"
                    onClick={() => {
                      setSegment("detail");
                    }}
                  >
                    <IonLabel>{t("detail")}</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="assessment"
                    onClick={() => {
                      setSegment("assessment");
                    }}
                  >
                    <IonLabel>SPKO</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="map"
                    onClick={() => {
                      setSegment("map");
                    }}
                  >
                    <IonLabel>{t("map")}</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
                <IonCardContent className="ion-no-padding ion-no-margin">
                  {segment === "information" && (
                    <IonList>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder={`${t("name")} ${t("module.customer")}*`}
                          label={`${t("name")} ${t("module.customer")}*`}
                          name="customer_profile_name"
                          value={
                            selectCustomer?.customer_name ??
                            customerProfile.customer_profile_name
                          }
                          required={true}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_name: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_name: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonSelect
                          label="Title*"
                          labelPlacement="stacked"
                          name="customer_profile_title"
                          value={
                            selectCustomer?.customer_title ??
                            customerProfile.customer_profile_title
                          }
                          onIonChange={(e) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_title: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_title: e.detail.value,
                            }));
                          }}
                        >
                          {Object.entries(title).map(([key, value]) => (
                            <IonSelectOption key={key} value={value}>
                              {value}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonSelect
                          label="Tipe*"
                          labelPlacement="stacked"
                          name="customer_profile_customer_type_id"
                          value={
                            selectCustomer?.customer_customer_type_id ??
                            customerProfile.customer_profile_customer_type_id
                          }
                          onIonChange={(e) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_type_id: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_customer_type_id: e.detail.value,
                            }));
                          }}
                        >
                          {customerType.map((item: any, key: number) => (
                            <IonSelectOption
                              key={key}
                              value={item.customer_type_id}
                            >
                              {item.customer_type_name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="Area*"
                          label="Area*"
                          name="customer_profile_subarea_id"
                          value={subAreaName}
                          required={true}
                          readonly={true}
                          onClick={() => {
                            setShowModalSelectOptionSubArea(true);
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="Alamat 1*"
                          label="Alamat 1"
                          name="customer_profile_address1"
                          value={
                            selectCustomer?.customer_address1 ??
                            customerProfile.customer_profile_address1
                          }
                          required={true}
                          onIonInput={(e: any) => {
                            if (e.detail.value.length > 72) {
                              massageAlert(
                                "error",
                                "Alamat terlalu panjang maximal 72 karakter "
                              );
                              return;
                            }
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_address1: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_address1: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="Alamat 2"
                          label="Alamat 2"
                          name="customer_profile_address2"
                          value={
                            selectCustomer?.customer_address2 ??
                            customerProfile.customer_profile_address2
                          }
                          required={false}
                          onIonInput={(e: any) => {
                            if (e.detail.value.length > 72) {
                              massageAlert(
                                "error",
                                "Alamat terlalu panjang maximal 72 karakter "
                              );
                              return;
                            }
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_address2: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_address2: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="Alamat Kirim 1*"
                          label="Alamat Kirim 1*"
                          name="customer_profile_ship_address1"
                          value={
                            selectCustomer?.customer_ship_address1 ??
                            customerProfile.customer_profile_ship_address1
                          }
                          required={true}
                          onIonInput={(e: any) => {
                            if (e.detail.value.length > 72) {
                              massageAlert(
                                "error",
                                "Alamat terlalu panjang maximal 72 karakter "
                              );
                              return;
                            }
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_ship_address1: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_ship_address1: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="Alamat Kirim 2"
                          label="Alamat Kirim 2"
                          name="customer_profile_ship_address2"
                          value={
                            selectCustomer?.customer_ship_address2 ??
                            customerProfile.customer_profile_ship_address2
                          }
                          required={false}
                          onIonInput={(e: any) => {
                            if (e.detail.value.length > 72) {
                              massageAlert(
                                "error",
                                "Alamat terlalu panjang maximal 72 karakter "
                              );
                              return;
                            }
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_ship_address2: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_ship_address2: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="number"
                          labelPlacement="floating"
                          placeholder="Kode Pos"
                          minlength={1}
                          maxlength={5}
                          label="Kode Pos"
                          name="customer_profile_zipcode"
                          value={
                            selectCustomer?.customer_zipcode ??
                            customerProfile.customer_profile_zipcode
                          }
                          required={false}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_zipcode: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_zipcode: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="Telepon*"
                          label="Telepon*"
                          name="customer_profile_phone"
                          value={
                            selectCustomer?.customer_phone ??
                            customerProfile.customer_profile_phone
                          }
                          required={true}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_phone: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_phone: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="number"
                          labelPlacement="floating"
                          placeholder="Fax"
                          label="Fax"
                          name="customer_profile_fax"
                          value={
                            selectCustomer?.customer_fax ??
                            customerProfile.customer_profile_fax
                          }
                          required={false}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_fax: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_fax: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="email"
                          labelPlacement="floating"
                          placeholder="Email Kontak"
                          label="Email Kontak"
                          name="customer_profile_email"
                          value={
                            selectCustomer?.customer_email ??
                            customerProfile.customer_profile_email
                          }
                          required={false}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_email: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_email: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="Kota*"
                          label="Kota*"
                          name="customer_profile_city"
                          value={
                            selectCustomer?.customer_city ??
                            customerProfile.customer_profile_city
                          }
                          required={true}
                          readonly={true}
                          onClick={() => {
                            setShowModalSelectOptionCity(true);
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="Propinsi*"
                          label="Propinsi*"
                          name="customer_profile_state"
                          value={
                            selectCustomer?.customer_state ??
                            customerProfile.customer_profile_state
                          }
                          required={true}
                          readonly={true}
                          onClick={() => {
                            setShowModalSelectOptionState(true);
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonSelect
                          label="Negara*"
                          labelPlacement="stacked"
                          name="customer_profile_country"
                          value={
                            selectCustomer?.customer_country ??
                            customerProfile.customer_profile_country
                          }
                          onIonChange={(e) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_country: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_country: e.detail.value,
                            }));
                          }}
                        >
                          {country.map((item: any, key: number) => (
                            <IonSelectOption
                              key={key}
                              value={item.country_name}
                            >
                              {`${item.country_name}`}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonList>
                  )}
                  {segment === "contact" && (
                    <IonList>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="Nama Kontak*"
                          label="Nama Kontak*"
                          name="customer_profile_contact_name"
                          value={
                            selectCustomer?.customer_contact_name ??
                            customerProfile.customer_profile_contact_name
                          }
                          required={true}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_contact_name: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_contact_name: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="HP Kontak*"
                          label="HP Kontak*"
                          name="customer_profile_contact_mobile"
                          value={
                            selectCustomer?.customer_contact_mobile ??
                            customerProfile.customer_profile_contact_mobile
                          }
                          required={true}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_contact_mobile: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_contact_mobile: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="Jabatan Kontak*"
                          label="Jabatan Kontak*"
                          name="customer_profile_contact_title"
                          value={
                            selectCustomer?.customer_contact_title ??
                            customerProfile.customer_profile_contact_title
                          }
                          required={true}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_contact_title: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_contact_title: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="email"
                          labelPlacement="floating"
                          placeholder="Email Kontak"
                          label="Email Kontak"
                          name="customer_profile_contact_email"
                          value={
                            selectCustomer?.customer_contact_email ??
                            customerProfile.customer_profile_contact_email
                          }
                          required={false}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_contact_email: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_contact_email: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="Hobby"
                          label="Hobby"
                          name="customer_profile_contact_hobby"
                          value={
                            selectCustomer?.customer_contact_hobby ??
                            customerProfile.customer_profile_contact_hobby
                          }
                          required={false}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_contact_hobby: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_contact_hobby: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonTextarea
                          placeholder="Alamat Tinggal"
                          labelPlacement="floating"
                          label="Alamat Tinggal"
                          name="customer_profile_contact_address"
                          value={
                            selectCustomer?.customer_contact_address ??
                            customerProfile.customer_profile_contact_address
                          }
                          required={false}
                          onIonInput={(e: any) => {
                            if (e.detail.value.length > 72) {
                              massageAlert(
                                "error",
                                "Alamat terlalu panjang maximal 72 karakter "
                              );
                              return;
                            }
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_contact_address: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_contact_address: e.detail.value,
                            }));
                          }}
                        ></IonTextarea>
                      </IonItem>
                      <IonItem>
                        <IonText>
                          <span>{t("date")} Lahir : </span>
                        </IonText>
                        <IonButton
                          color="light"
                          slot="end"
                          fill="clear"
                          onClick={() => {
                            setShowModalDate(true);
                            setNameDate(`customer_profile_contact_birthday`);
                          }}
                        >
                          <IonText color={"dark"}>
                            {getNameDate(
                              customerProfile.customer_profile_contact_birthday
                            )}
                            <IonIcon color="danger" src={calendar} />
                          </IonText>
                        </IonButton>
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="Nama KTP"
                          label="Nama KTP*"
                          name="customer_profile_identity_card_name"
                          value={
                            selectCustomer?.customer_identity_card_name ??
                            customerProfile.customer_profile_identity_card_name
                          }
                          required={false}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_identity_card_name: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_identity_card_name:
                                e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="No. KTP"
                          label="No. KTP*"
                          inputMode="numeric"
                          name="customer_profile_identity_card_number"
                          value={
                            selectCustomer?.customer_identity_card_number ??
                            customerProfile.customer_profile_identity_card_number
                          }
                          ref={async (identityCardRef) => {
                            if (identityCardRef) {
                              identityCardMask(
                                await identityCardRef.getInputElement()
                              );
                            }
                          }}
                          required={false}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_identity_card_number: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_identity_card_number:
                                e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="Nama NPWP"
                          label="Nama NPWP"
                          name="customer_profile_tax_name"
                          value={
                            selectCustomer?.customer_tax_name ??
                            customerProfile.customer_profile_tax_name
                          }
                          required={false}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_tax_name: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_tax_name: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="00.000.000.0-000.000"
                          label="No. NPWP"
                          inputMode="numeric"
                          name="customer_profile_tax_number"
                          ref={async (taxRef) => {
                            if (taxRef) {
                              taxMask(await taxRef.getInputElement());
                            }
                          }}
                          value={
                            selectCustomer?.customer_tax_number ??
                            customerProfile.customer_profile_tax_number
                          }
                          required={false}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_tax_number: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_tax_number: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                    </IonList>
                  )}
                  {segment === "detail" && (
                    <IonList className="ion-no-padding ion-no-margin">
                      <IonItem className="ion-no-padding">
                        <IonCheckbox
                          name="customer_profile_is_active"
                          checked={
                            selectCustomer.customer_is_active ??
                            customerProfile.customer_profile_is_active
                          }
                          onIonChange={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_is_active: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_is_active: e.target.checked
                                ? 1
                                : 0,
                            }));
                          }}
                        >
                          <span>&nbsp; &nbsp; {t("is_active")} </span>
                        </IonCheckbox>
                      </IonItem>

                      <IonItem>
                        <IonInput
                          type="text"
                          labelPlacement="floating"
                          placeholder="Website Url"
                          label="Website Url"
                          name="customer_profile_url"
                          value={
                            selectCustomer?.customer_url ??
                            customerProfile.customer_profile_url
                          }
                          required={false}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_url: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_url: e.detail.value,
                            }));
                          }}
                        />
                      </IonItem>
                      <IonItem>
                        <IonTextarea
                          placeholder="Keterangan"
                          labelPlacement="floating"
                          label="Keterangan"
                          name="customer_profile_remark"
                          value={
                            selectCustomer?.customer_remark ??
                            customerProfile.customer_profile_remark
                          }
                          required={false}
                          onIonInput={(e: any) => {
                            setSelectCustomer((prevState: any) => ({
                              ...prevState,
                              customer_remark: null,
                            }));
                            setCustomerProfile((prevState: any) => ({
                              ...prevState,
                              customer_profile_remark: e.detail.value,
                            }));
                          }}
                        ></IonTextarea>
                      </IonItem>
                    </IonList>
                  )}
                  {segment === "map" && isLoaded && (
                    <>
                      <GoogleMap
                        mapContainerClassName="google-map"
                        center={{
                          lat: Number(
                            selectCustomer?.customer_lat ??
                              customerProfile?.customer_lat ??
                              coordinates.coords.latitude
                          ),
                          lng: Number(
                            selectCustomer?.customer_lng ??
                              customerProfile?.customer_lng ??
                              coordinates.coords.longitude
                          ),
                        }}
                        zoom={17}
                        options={{
                          gestureHandling: "none",
                          streetViewControl: false,
                          mapTypeControl: false,
                          fullscreenControl: false,
                          zoomControl: false,
                          clickableIcons: false,
                          keyboardShortcuts: false,
                        }}
                      >
                        <Marker
                          icon={{
                            path: "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
                            fillColor: "yellow",
                            fillOpacity: 0.9,
                            scale: 2,
                            strokeColor: "gold",
                            strokeWeight: 2,
                          }}
                          position={{
                            lat: Number(
                              selectCustomer?.customer_lat ??
                                customerProfile?.customer_lat ??
                                coordinates.coords.latitude
                            ),
                            lng: Number(
                              selectCustomer?.customer_lng ??
                                customerProfile?.customer_lng ??
                                coordinates.coords.longitude
                            ),
                          }}
                          onClick={() => setOnLoadInfo(!onLoadInfo)}
                        />

                        {onLoadInfo && (
                          <InfoWindow
                            onCloseClick={() => setOnLoadInfo(!onLoadInfo)}
                            position={{
                              lat: Number(
                                selectCustomer?.customer_lat ??
                                  customerProfile?.customer_lat ??
                                  coordinates.coords.latitude
                              ),
                              lng: Number(
                                selectCustomer?.customer_lng ??
                                  customerProfile?.customer_lng ??
                                  coordinates.coords.longitude
                              ),
                            }}
                          >
                            <IonText className="ion-no-padding">
                              <h4>
                                <IonIcon
                                  size="small"
                                  className="ion-no-padding"
                                  src={storefrontSharp}
                                />
                                <strong>{selectCustomer?.customer_name}</strong>
                              </h4>
                              <p>
                                <strong>
                                  {selectCustomer?.customer_address1}
                                </strong>
                              </p>
                            </IonText>
                          </InfoWindow>
                        )}
                      </GoogleMap>

                      <IonFab slot="fixed" vertical="bottom" horizontal="start">
                        <IonBadge color={"medium"}>
                          <h3>
                            {`${Number(
                              selectCustomer?.customer_lat ??
                                customerProfile?.customer_lat ??
                                coordinates.coords.latitude
                            )},${Number(
                              selectCustomer?.customer_lng ??
                                customerProfile?.customer_lng ??
                                coordinates.coords.longitude
                            )}`}
                          </h3>
                        </IonBadge>
                      </IonFab>

                      <IonFab slot="fixed" vertical="bottom" horizontal="end">
                        <IonFabButton
                          size="small"
                          color={"danger"}
                          data-desc="Set Lokasi"
                          onClick={setLocation}
                        >
                          <IonIcon icon={locateOutline}></IonIcon>
                        </IonFabButton>
                      </IonFab>
                    </>
                  )}
                  {segment === "assessment" && (
                    <IonCard className="ion-no-padding ion-no-margin">
                      <IonCardHeader className="ion-no-padding ion-no-margin">
                        <IonItem>
                          <IonText>
                            <strong>SPKO</strong>
                          </IonText>
                          <IonText slot="end">
                            <strong>Total</strong>
                          </IonText>
                        </IonItem>
                      </IonCardHeader>

                      <IonCardContent className="ion-no-padding ion-no-margin">
                        {assessment.map((valAsType: any, keyAsType: number) => (
                          <React.Fragment key={keyAsType}>
                            <IonCard>
                              <IonCardHeader className="ion-no-padding ion-no-margin">
                                <IonText>
                                  <strong>
                                    {valAsType.assessment_type_name}
                                  </strong>
                                </IonText>
                              </IonCardHeader>
                              <IonCardContent>
                                {valAsType.assessment.map(
                                  (valAs: any, keyAs: number) => (
                                    <React.Fragment key={keyAs}>
                                      <IonItem className="ion-no-padding ion-no-margin">
                                        <IonText>
                                          {valAs.assessment_name}
                                        </IonText>
                                        <IonText slot="end">
                                          {valAs.assessment_weight}
                                        </IonText>
                                      </IonItem>
                                      <IonList>
                                        <IonRadioGroup
                                          value={setValRadioAssessment(
                                            valAs.assessment_value
                                          )}
                                        >
                                          {valAs.assessment_value.map(
                                            (value: any, keyList: number) => (
                                              <React.Fragment key={keyList}>
                                                <IonItem
                                                  className="ion-no-padding ion-no-margin"
                                                  key={keyList}
                                                  lines="none"
                                                >
                                                  <IonRadio
                                                    className="ion-no-padding ion-no-margin ion-text-nowrap"
                                                    value={value}
                                                    justify="space-between"
                                                    onClick={async () => {
                                                      valAs.assessment_value.forEach(
                                                        (
                                                          value: any,
                                                          keyIn: number
                                                        ) => {
                                                          if (
                                                            keyIn === keyList
                                                          ) {
                                                            value.selected =
                                                              true;
                                                          } else {
                                                            value.selected =
                                                              false;
                                                          }
                                                        }
                                                      );
                                                      let total = 0;
                                                      await setAssessment(
                                                        (prevState: any) => {
                                                          // Loop over your list
                                                          prevState.forEach(
                                                            (val: any) => {
                                                              let countSubTotal = 0;
                                                              val.assessment.forEach(
                                                                (
                                                                  valList: any
                                                                ) => {
                                                                  let getSelected =
                                                                    setValRadioAssessment(
                                                                      valList.assessment_value
                                                                    );
                                                                  valList.total =
                                                                    Number(
                                                                      getSelected?.weight ??
                                                                        0
                                                                    ) *
                                                                    Number(
                                                                      valList.assessment_weight
                                                                    );
                                                                  countSubTotal +=
                                                                    valList.total;
                                                                }
                                                              );
                                                              val.countSubTotal =
                                                                countSubTotal;
                                                              total +=
                                                                countSubTotal;
                                                            }
                                                          );
                                                          return prevState;
                                                        }
                                                      );

                                                      if (setting[1]) {
                                                        let setting_json_index_assessment =
                                                          JSON.parse(
                                                            setting[1]
                                                              .settings_value
                                                          );
                                                        const itemInRange =
                                                          setting_json_index_assessment.sort(
                                                            (a: any, b: any) =>
                                                              Number(
                                                                b.attaintement_rate
                                                              ) -
                                                              Number(
                                                                a.attaintement_rate
                                                              )
                                                          );
                                                        const item =
                                                          itemInRange.find(
                                                            (item: any) =>
                                                              total >=
                                                              item.attaintement_rate
                                                          );
                                                        await setindexAssessment(
                                                          `${item.class} - (${item.category}), ${item.decision}`
                                                        );
                                                      }
                                                      await setCountTotal(
                                                        total
                                                      );
                                                    }}
                                                  >
                                                    {value.name} ({value.weight}
                                                    )
                                                  </IonRadio>
                                                </IonItem>
                                              </React.Fragment>
                                            )
                                          )}
                                        </IonRadioGroup>
                                      </IonList>
                                      <IonItem>
                                        <IonText slot="end">
                                          {valAs?.total?.toFixed(1) ?? 0}
                                        </IonText>
                                      </IonItem>
                                    </React.Fragment>
                                  )
                                )}
                              </IonCardContent>
                              <IonItem className="ion-no-padding ion-no-margin">
                                <IonText slot="end">
                                  <strong>
                                    Sub Total ={" "}
                                    {valAsType?.countSubTotal?.toFixed(1) ?? 0}
                                  </strong>
                                </IonText>
                              </IonItem>
                            </IonCard>
                          </React.Fragment>
                        ))}
                      </IonCardContent>
                      <IonItem className="ion-no-padding ion-no-margin">
                        <IonText slot="end" class="ion-text-end">
                          <p>
                            <strong>Total = {countTotal?.toFixed(1)}</strong>
                          </p>
                          <IonText color="primary">
                            <p>{indexAssessment}</p>
                          </IonText>
                        </IonText>
                      </IonItem>
                      {setting[0] && (
                        <IonItem className="ion-no-padding ion-no-margin">
                          <div
                            className="ion-no-padding ion-no-margin"
                            dangerouslySetInnerHTML={{
                              __html: setting[0].settings_value,
                            }}
                          ></div>
                        </IonItem>
                      )}
                    </IonCard>
                  )}
                </IonCardContent>
              </IonCard>
              <IonCard className="ion-no-padding">
                <IonTitle className="ion-padding-start ion-no-margin">
                  <strong>Upload Foto Pelanggan</strong>
                </IonTitle>
                <IonCardContent className="ion-no-padding ion-no-margin">
                  <IonSegment
                    scrollable={true}
                    value={segmentImage[segmentImageCurrent].customer}
                    className="ion-no-padding ion-no-margin"
                  >
                    <IonSegmentButton
                      value="customer_identity_card_image"
                      onClick={() => {
                        changeImageSegment("customer_identity_card_image");
                      }}
                    >
                      <IonLabel>KTP</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton
                      value="customer_image1"
                      onClick={() => {
                        changeImageSegment("customer_image1");
                      }}
                    >
                      <IonLabel>Foto 1</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton
                      value="customer_image2"
                      onClick={() => {
                        changeImageSegment("customer_image2");
                      }}
                    >
                      <IonLabel>Foto 2</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton
                      value="customer_image3"
                      onClick={() => {
                        changeImageSegment("customer_image3");
                      }}
                    >
                      <IonLabel>Foto 3</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton
                      value="customer_image4"
                      onClick={() => {
                        changeImageSegment("customer_image4");
                      }}
                    >
                      <IonLabel>Foto 4</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                  <IonImg
                    src={
                      segmentImage[segmentImageCurrent].photo
                        ? segmentImage[segmentImageCurrent].photo.webviewPath
                        : selectCustomer[
                            segmentImage[segmentImageCurrent].customer
                          ]
                        ? selectCustomer[
                            segmentImage[segmentImageCurrent].customer
                          ]
                        : customerProfile[
                            segmentImage[segmentImageCurrent].customerProfile
                          ]
                        ? customerProfile[
                            segmentImage[segmentImageCurrent].customerProfile
                          ]
                        : "assets/img/insert-image.png"
                    }
                    onClick={selectImageSource}
                  />
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonButton
                  size="small"
                  color={"danger"}
                  fill={"solid"}
                  type="submit"
                  expand="full"
                >
                  <IonIcon icon={saveOutline}></IonIcon> {" " + t(props.type)}
                </IonButton>
              </IonCard>
            </form>
          </IonContent>
          <SelectOption
            title={t("module.customer")}
            items={[
              {
                value: customerProfile.customer_profile_customer_id,
                text: `${t("add")} ${t("module.customer")} - (${
                  customerProfile.customer_profile_customer_id
                })`,
              },
              ...customer.map((value: any) => ({
                value: value.customer_id,
                text: `${value.customer_name} - (${value.customer_id})`,
              })),
            ]}
            selectedItems={[customerProfile.customer_profile_customer_id]}
            onSelectionCancel={() =>
              setShowModalSelectOptionCustomerId(
                !showModalSelectOptionCustomerId
              )
            }
            onSelectionChange={customerSelectionChanged}
            multiple={false}
            isOpen={showModalSelectOptionCustomerId}
          />
          <SelectOption
            title={"Kota*"}
            items={city.map((value: any) => ({
              value: value.city_id,
              text: `${value.city_name}`,
            }))}
            selectedItems={[
              selectCustomer?.customer_city ??
                customerProfile.customer_profile_city,
            ]}
            onSelectionCancel={() =>
              setShowModalSelectOptionCity(!showModalSelectOptionCity)
            }
            onSelectionChange={citySelectionChanged}
            multiple={false}
            isOpen={showModalSelectOptionCity}
          />
          <SelectOption
            title={"Propinsi*"}
            items={state.map((value: any) => ({
              value: value.state_id,
              text: `${value.state_name}`,
            }))}
            selectedItems={[
              selectCustomer?.customer_state ??
                customerProfile.customer_profile_customer_state,
            ]}
            onSelectionCancel={() =>
              setShowModalSelectOptionState(!showModalSelectOptionState)
            }
            onSelectionChange={stateSelectionChanged}
            multiple={false}
            isOpen={showModalSelectOptionState}
          />
          <SelectOption
            title={"Area*"}
            items={subArea.map((item: any) => ({
              value: item.subarea_id,
              text: `${item.subarea_name}, ${item.area_name}`,
            }))}
            selectedItems={[
              selectCustomer?.customer_subarea_id ??
                customerProfile.customer_profile_customer_subarea_id,
            ]}
            onSelectionCancel={() =>
              setShowModalSelectOptionSubArea(!showModalSelectOptionSubArea)
            }
            onSelectionChange={subAreaSelectionChanged}
            multiple={false}
            isOpen={showModalSelectOptionSubArea}
          />
          <InputDate
            onCloseModal={handleModalDateClose}
            initialData={customerProfile.customer_profile_visit_date}
            isOpen={showModalDate}
          />
        </>
      ) : (
        <IonList>
          <IonListHeader>
            <IonSkeletonText
              animated={true}
              style={{ width: "80px" }}
            ></IonSkeletonText>
          </IonListHeader>
          {loadList.map((value: any, key: any) => (
            <IonItem key={key}>
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonThumbnail>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      )}
    </IonPage>
  );
};

export default Form;
