import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonCard,
  IonContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonBadge,
  IonText,
  IonFab, 
  IonFabButton, 
  IonList,
  IonImg,
  IonFabList,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail,
  IonRefresher, 
  IonRefresherContent,
  IonPage,
  useIonAlert,
  useIonToast,
  useIonLoading,
  IonTitle,
  IonCardContent,
  IonSegment,
  IonSegmentButton,
  useIonModal,
  IonCardHeader,
  IonRadioGroup,
  IonRadio,
} from '@ionic/react';
import React, { useState} from 'react';
import { RefresherEventDetail } from '@ionic/core';
import { chevronDownCircleOutline, arrowBackOutline, menuSharp, checkmarkCircleOutline, closeCircleOutline, storefrontSharp, calendar, createOutline, warningOutline, personCircleOutline, checkmarkDoneCircleOutline} from "ionicons/icons";
import { RestApi } from '../../services/Api';
import { CustomerProfile } from "../../models/Modal";
import { useTranslation } from 'react-i18next';
import { getNameDate, getRoleMenu } from '../../services/Library';
import { useSelector } from 'react-redux';
import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import Form from './Form';
import './Detail.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const Detail: React.FC<CustomerProfile> = (props) =>{
  const [customerProfile, setCustomerProfile] = useState<any>([]);
  const [alert] = useIonAlert();
  const { t } = useTranslation(["general"]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const user = useSelector((state: any) => state.user.value);
  const getRole = getRoleMenu(user.role_menu, "customer_profile");
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentToast] = useIonToast();
  const [segment, setSegment] = useState<string>("information");
  const [onLoadInfo, setOnLoadInfo] = useState<boolean>(false);
  const [segmentImage, setSegmentImage] = useState<string>(
    "customer_profile_identity_card_image"
  );
  let countTotal:number = 0
   const status: any = [
     {
       name: t("cancel"),
       color: "danger",
     },
     {
       name: t("check"),
       color: "secondary",
     },
     {
       name: t("wait-approve"),
       color: "warning",
     },
     {
       name: t("approve"),
       color: "success",
     },
   ];

  const massageAlert = async (status: string, body: string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [{ cssClass: "alert-button-confirm", text: "Ok" }],
    });
  };

  const handleDismissForm = () => {
    dismissForm();
    getCustomerDetailCustomerProfile(props.idCustomerProfile);
  };


  const [presentForm, dismissForm] = useIonModal(Form, {
    idCustomerProfile: props.idCustomerProfile,
    type: "edit",
    onDismiss: handleDismissForm,
  });

  const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
      getCustomerDetailCustomerProfile(props.idCustomerProfile)
      event.detail.complete();
  }

  const getCustomerDetailCustomerProfile = (id:string) => {
    if(!id) {
      props.onDismiss()
      return;
    }
    setLoaded(false)
    RestApi(`customer-profile/${id}`,'get',{},'application/json')
    .then(async(res:any) => {
        if (res.data.code === 200) {
          setCustomerProfile(res.data.data)
          setLoaded(true)
        }else{
          props.onDismiss()
          massageAlert('error',res.data.message)
        }
    }).catch((err:any) => {
        props.onDismiss()
        massageAlert('error',err.message)
    });
  }

  const processAlert = (status:Number,field:string) => {
    let text = field;
    switch (text) {
      case "check":
        text = "Periksa";
        break;
      case "approve":
        text = "DiSetujui";
        break;
      default:
        break;
    }
    alert({
      header: `Keterangan ${text}?`,
      cssClass: "header-alert",
      buttons: [
        {
          text: "X",
          cssClass: "alert-button-close",
          role: "close",
        },
        {
          text: "Batal",
          cssClass: "alert-button-cancel",
          role: "cancel",
        },
        {
          text: text,
          cssClass: "alert-button-confirm",
          role: "confirm",
        },
      ],
      inputs: [
        {
          placeholder: "Note",
          type: "textarea",
          name: `note`,
        },
      ],
      onDidDismiss: async (e: any) => {
        if (e.detail.role === "confirm") {
          if (!e.detail.data.values.note) {
            presentToast({
              message: t("required"),
              duration: 1500,
              icon: warningOutline,
            });
            return;
          } else {
            await sendConfirm(status, e.detail.data.values.note, field);
          }
        } else if (e.detail.role === "cancel") {
          if (!e.detail.data.values.note) {
            presentToast({
              message: t("required"),
              duration: 1500,
              icon: warningOutline,
            });
            return;
          } else {
            await sendConfirm(0, e.detail.data.values.note, field);
          }
        }
      },
    });
  };
  
  const sendConfirm = async (status: Number, note: string, field:string) => {
    await presentLoading({ message: t("loading") });    
    await RestApi(
      `customer-profile/update-${field}/${customerProfile.customer_profile_id}`,
      "post",
      {
        status: status,
        note: note,
      }
    )
      .then(async (res: any) => {
        if (res.data.code === 200) {
          getCustomerDetailCustomerProfile(props.idCustomerProfile);
          presentToast({
            message: res.data.message,
            duration: 1500,
            icon: checkmarkCircleOutline,
            color: "success",
          });
          dismissLoading();
        } else {
          presentToast({
            message: res.data.message,
            duration: 1500,
            icon: closeCircleOutline,
            color: "danger",
          });
          dismissLoading();
        }
      })
      .catch((error: any) => {
        presentToast({
          message: error.message,
          duration: 1500,
          icon: closeCircleOutline,
          color: "danger",
        });
        dismissLoading();
      });
  };

  const setValRadioAssessment = (val: any) => {
    return val.find((item: any) => item.selected === true);
  };

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAP || "",
  });

  useConstructor(() => {
    getCustomerDetailCustomerProfile(props.idCustomerProfile)
  });
    
  return (
    <IonPage>
      <IonHeader>
        {loaded ? (
          <IonToolbar class="toolbar-detail-customer-profile ion-no-padding ion-no-margin">
            <IonButton
              color="light"
              slot="start"
              fill="clear"
              onClick={() => props.onDismiss()}
            >
              <IonIcon color="light" slot="start" src={arrowBackOutline} />
            </IonButton>
            <IonItem className="ion-no-padding" lines="none">
              <IonLabel className="ion-no-padding" color="light">
                <h4>
                  <strong>
                    {customerProfile.customer_profile_name},
                    {customerProfile.customer_profile_title + " "}
                  </strong>
                  ({customerProfile.customer_profile_customer_id})
                </h4>
              </IonLabel>
              <IonBadge
                slot="end"
                color={status[customerProfile.customer_profile_status].color}
              >
                {status[customerProfile.customer_profile_status].name}
              </IonBadge>
            </IonItem>
          </IonToolbar>
        ) : (
          <IonToolbar>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent className="detail-customer-profile-content">
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={`${t("loading")}`}
            refreshingSpinner="circles"
            refreshingText={`${t("loading")}`}
          ></IonRefresherContent>
        </IonRefresher>
        {!loaded && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: "80px" }}
              ></IonSkeletonText>
            </IonListHeader>
            <IonItem>
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true}></IonSkeletonText>
              </IonThumbnail>
              <IonLabel>
                <h3>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "80%" }}
                  ></IonSkeletonText>
                </h3>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "60%" }}
                  ></IonSkeletonText>
                </p>
                <p>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: "30%" }}
                  ></IonSkeletonText>
                </p>
              </IonLabel>
            </IonItem>
          </IonList>
        )}
        {customerProfile && loaded ? (
          <>
            <IonCard className="ion-no-padding">
              <IonItem className="ion-no-padding ion-no-margin">
                <IonText className="ion-padding-start" color={"dark"}>
                  <strong>{t("module.customer-profile")}</strong>
                </IonText>
                <IonText slot="end">
                  {getNameDate(customerProfile.customer_profile_visit_date)}
                  <IonIcon color="danger" src={calendar} />
                </IonText>
              </IonItem>
              <IonItem className="ion-no-padding ion-no-margin">
                <IonText className="ion-padding-start ion-no-margin">
                  <span>
                    {t("note")} Survey :{" "}
                    {customerProfile.customer_profile_visit_note}
                  </span>
                  <span>
                    <IonIcon
                      size="small"
                      className="ion-no-padding"
                      src={personCircleOutline}
                    />{" "}
                    :{" "}
                    {customerProfile.customer_profile_create_by +
                      " - " +
                      getNameDate(
                        customerProfile.customer_profile_create_at,
                        true
                      )}
                  </span>
                </IonText>
              </IonItem>
              {customerProfile.customer_profile_check_at ? (
                <IonItem>
                  <IonText>
                    <span>
                      {t("note")} Periksa :{" "}
                      {customerProfile.customer_profile_check_note}
                    </span>
                    <span>
                      <IonIcon
                        size="small"
                        className="ion-no-padding"
                        src={checkmarkCircleOutline}
                      />{" "}
                      :{" "}
                      {customerProfile.customer_profile_check_by +
                        " - " +
                        getNameDate(
                          customerProfile.customer_profile_check_at,
                          true
                        )}
                    </span>
                  </IonText>
                </IonItem>
              ) : (
                ""
              )}
              {customerProfile.customer_profile_approve_at ? (
                <IonItem>
                  <IonText>
                    <span>
                      {t("note")} Disetujui :{" "}
                      {customerProfile.customer_profile_approve_note}
                    </span>
                    <span>
                      <IonIcon
                        size="small"
                        className="ion-no-padding"
                        src={checkmarkDoneCircleOutline}
                      />{" "}
                      :{" "}
                      {customerProfile.customer_profile_approve_by +
                        " - " +
                        getNameDate(
                          customerProfile.customer_profile_approve_at,
                          true
                        )}
                    </span>
                  </IonText>
                </IonItem>
              ) : (
                ""
              )}
            </IonCard>
            <IonCard className="ion-no-padding">
              <IonTitle className="ion-padding-start ion-no-margin">
                <strong>CPF</strong>
              </IonTitle>
              <hr />
              <IonSegment
                scrollable={true}
                value={segment}
                className="ion-no-padding ion-no-margin"
              >
                <IonSegmentButton
                  value="information"
                  onClick={() => {
                    setSegment("information");
                  }}
                >
                  <IonLabel>{t("information")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="contact"
                  onClick={() => {
                    setSegment("contact");
                  }}
                >
                  <IonLabel>{t("contact")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="detail"
                  onClick={() => {
                    setSegment("detail");
                  }}
                >
                  <IonLabel>{t("detail")}</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="assessment"
                  onClick={() => {
                    setSegment("assessment");
                  }}
                >
                  <IonLabel>SPKO</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="map"
                  onClick={() => {
                    setSegment("map");
                  }}
                >
                  <IonLabel>{t("map")}</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              <IonCardContent className="ion-no-padding ion-no-margin">
                {segment === "information" && (
                  <IonText className="ion-padding-start">
                    <p>
                      Handphone :
                      <strong>{customerProfile.customer_profile_phone}</strong>
                    </p>
                    <p>
                      Alamat kirim :
                      <strong>
                        {customerProfile.customer_profile_ship_address1 +
                          " " +
                          customerProfile.customer_profile_ship_address2}
                      </strong>
                    </p>
                    <p>
                      Kode Pos :
                      <strong>
                        {customerProfile.customer_profile_zipcode}
                      </strong>
                    </p>
                    <p>
                      Telpon :
                      <strong>{customerProfile.customer_profile_phone}</strong>
                    </p>
                    <p>
                      Fax :
                      <strong>{customerProfile.customer_profile_fax}</strong>
                    </p>
                    <p>
                      Email :
                      <strong> {customerProfile.customer_profile_email}</strong>
                    </p>
                    <p>
                      Status :
                      <strong>
                        {customerProfile.customer_profile_is_active
                          ? "AKTIF"
                          : "TIDAK AKTIF"}
                      </strong>
                    </p>

                    <p>
                      Area :
                      <strong>
                        {customerProfile.subarea_name} -
                        {customerProfile.area_name}
                      </strong>
                    </p>
                    <p>
                      Kota :
                      <strong> {customerProfile.customer_profile_city}</strong>
                    </p>
                    <p>
                      Propinsi :
                      <strong> {customerProfile.customer_profile_state}</strong>
                    </p>
                    <p>
                      Negara :
                      <strong>
                        {customerProfile.customer_profile_country}
                      </strong>
                    </p>
                  </IonText>
                )}
                {segment === "contact" && (
                  <IonText className="ion-padding-start">
                    <p>
                      Nama Kontak :
                      <strong>
                        {customerProfile.customer_profile_contact_name}
                      </strong>
                    </p>
                    <p>
                      Tanggal Lahir :
                      <strong>
                        {getNameDate(
                          customerProfile.customer_profile_contact_birthday
                        )}
                      </strong>
                    </p>
                    <p>
                      Jabatan :
                      <strong>
                        {customerProfile.customer_profile_contact_title}
                      </strong>
                    </p>
                    <p>
                      Hobby :
                      <strong>
                        {customerProfile.customer_profile_contact_hobby}
                      </strong>
                    </p>
                    <p>
                      Handphone :
                      <strong>
                        {customerProfile.customer_profile_contact_mobile}
                      </strong>
                    </p>
                    <p>
                      Email :
                      <strong>
                        {customerProfile.customer_profile_contact_email}
                      </strong>
                    </p>
                    <p>
                      Nama KTP :
                      <strong>
                        {customerProfile.customer_profile_identity_card_name}
                      </strong>
                    </p>
                    <p>
                      No. KTP :
                      <strong>
                        {customerProfile.customer_profile_identity_card_number}
                      </strong>
                    </p>
                    <p>
                      Nama NPWP :
                      <strong>
                        {customerProfile.customer_profile_tax_name}
                      </strong>
                    </p>
                    <p>
                      Nomor NPWP :
                      <strong>
                        {customerProfile.customer_profile_tax_number}
                      </strong>
                    </p>
                  </IonText>
                )}
                {segment === "detail" && (
                  <>
                    <IonText className="ion-padding-start">
                      <p>
                        Website URL :
                        <strong> {customerProfile.customer_profile_url}</strong>
                      </p>
                      <p>
                        Keterangan :
                        <strong>
                          {customerProfile.customer_profile_remark}
                        </strong>
                      </p>
                    </IonText>
                  </>
                )}
                {segment === "map" && isLoaded && (
                  <GoogleMap
                    mapContainerClassName="google-map"
                    center={{
                      lat: Number(
                        customerProfile.customer_profile_lat !== "null" ||
                          customerProfile.customer_profile_lat !== ""
                          ? customerProfile.customer_profile_lat
                          : user.branchs.branch_lat
                      ),
                      lng: Number(
                        customerProfile.customer_profile_lng !== "null" ||
                          customerProfile.customer_profile_lng !== ""
                          ? customerProfile.customer_profile_lng
                          : user.branchs.branch_lng
                      ),
                    }}
                    zoom={17}
                    options={{
                      gestureHandling: "none",
                      streetViewControl: false,
                      mapTypeControl: false,
                      fullscreenControl: false,
                      zoomControl: false,
                      clickableIcons: false,
                      keyboardShortcuts: false,
                    }}
                  >
                    <Marker
                      icon={{
                        path: "M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
                        fillColor: "yellow",
                        fillOpacity: 0.9,
                        scale: 2,
                        strokeColor: "gold",
                        strokeWeight: 2,
                      }}
                      position={{
                        lat: Number(
                          customerProfile.customer_profile_lat !== "null" ||
                            !customerProfile.customer_profile_lat
                            ? customerProfile.customer_profile_lat
                            : user.branchs.branch_lat
                        ),
                        lng: Number(
                          customerProfile.customer_profile_lng !== "null" ||
                            !customerProfile.customer_profile_lng
                            ? customerProfile.customer_profile_lng
                            : user.branchs.branch_lng
                        ),
                      }}
                      onClick={() => setOnLoadInfo(!onLoadInfo)}
                    />

                    {onLoadInfo && (
                      <InfoWindow
                        onCloseClick={() => setOnLoadInfo(!onLoadInfo)}
                        position={{
                          lat: Number(
                            customerProfile.customer_profile_lat !== "null" ||
                              !customerProfile.customer_profile_lat
                              ? customerProfile.customer_profile_lat
                              : user.branchs.branch_lat
                          ),
                          lng: Number(
                            customerProfile.customer_profile_lng !== "null" ||
                              !customerProfile.customer_profile_lng
                              ? customerProfile.customer_profile_lng
                              : user.branchs.branch_lng
                          ),
                        }}
                      >
                        <IonText className="ion-no-padding">
                          <h4>
                            <IonIcon
                              size="small"
                              className="ion-no-padding"
                              src={storefrontSharp}
                            />
                            <strong>
                              {customerProfile.customer_profile_name}
                            </strong>
                          </h4>
                          <p>
                            <strong>
                              {customerProfile.customer_profile_address1}
                            </strong>
                          </p>
                        </IonText>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                )}
                {segment === "assessment" && (
                  <IonCard className="ion-no-padding ion-no-margin">
                    <IonCardHeader className="ion-no-padding ion-no-margin">
                      <IonItem>
                        <IonText>
                          <strong>SPKO</strong>
                        </IonText>
                        <IonText slot="end">
                          <strong>Total</strong>
                        </IonText>
                      </IonItem>
                    </IonCardHeader>
                    <IonCardContent className="ion-no-padding ion-no-margin">
                      {customerProfile.assessment.map(
                        (valAsType: any, keyAsType: number) => {
                          let countSubTotal = 0;
                          return (
                            <IonCard key={keyAsType}>
                              <IonCardHeader className="ion-no-padding ion-no-margin">
                                <IonText>
                                  <strong>
                                    {valAsType.assessment_type_name}
                                  </strong>
                                </IonText>
                              </IonCardHeader>
                              <IonCardContent>
                                {valAsType.assessment.map(
                                  (valAs: any, keyAs: number) => {
                                    return (
                                      <div key={keyAs}>
                                        <IonItem className="ion-no-padding ion-no-margin">
                                          <IonText>
                                            {valAs.assessment_name}
                                          </IonText>
                                          <IonText slot="end">
                                            {valAs.assessment_weight}
                                          </IonText>
                                        </IonItem>
                                        <IonList>
                                          <IonRadioGroup
                                            value={setValRadioAssessment(
                                              valAs.assessment_value
                                            )}
                                          >
                                            {valAs.assessment_value.map(
                                              (value: any, key: number) => {
                                                return (
                                                  <IonItem
                                                    className="ion-no-padding ion-no-margin"
                                                    key={key}
                                                    lines="none"
                                                  >
                                                    <IonRadio
                                                      disabled={true}
                                                      className="ion-no-padding ion-no-margin"
                                                      value={value}
                                                      justify="space-between"
                                                    >
                                                      <strong>
                                                        {value.name} (
                                                        {value.weight})
                                                      </strong>
                                                    </IonRadio>
                                                  </IonItem>
                                                );
                                              }
                                            )}
                                          </IonRadioGroup>
                                        </IonList>
                                        <IonItem>
                                          <IonText slot="end">
                                            {(() => {
                                              let selected =
                                                setValRadioAssessment(
                                                  valAs.assessment_value
                                                );
                                              let total = Math.round(
                                                selected
                                                  ? Number(selected.weight) *
                                                      valAs.assessment_weight
                                                  : 0
                                              );
                                              countSubTotal += total;
                                              countTotal += total;
                                              return total?.toFixed(1);
                                            })()}
                                          </IonText>
                                        </IonItem>
                                      </div>
                                    );
                                  }
                                )}
                              </IonCardContent>
                              <IonItem className="ion-no-padding ion-no-margin">
                                <IonText slot="end">
                                  <strong>
                                    Sub Total = {countSubTotal?.toFixed(1)}
                                  </strong>
                                </IonText>
                              </IonItem>
                            </IonCard>
                          );
                        }
                      )}
                    </IonCardContent>
                    <IonItem className="ion-no-padding ion-no-margin">
                      <IonText slot="end" class="ion-text-end">
                        <p>
                          <strong>Total = {countTotal?.toFixed(1)}</strong>
                        </p>
                        <IonText color="primary">
                          <p>
                            {(() => {
                              if (customerProfile.setting[1]) {
                                let setting_json_index_assessment = JSON.parse(
                                  customerProfile.setting[1].settings_value
                                );
                                const itemInRange =
                                  setting_json_index_assessment.sort(
                                    (a: any, b: any) =>
                                      Number(b.attaintement_rate) -
                                      Number(a.attaintement_rate)
                                  );
                                const item = itemInRange.find(
                                  (item: any) =>
                                    countTotal >= item.attaintement_rate
                                );
                                return `${item.class} - (${item.category}), ${item.decision}`;
                              }
                            })()}
                          </p>
                        </IonText>
                      </IonText>
                    </IonItem>
                    {customerProfile.setting[0] && (
                      <IonItem className="ion-no-padding ion-no-margin">
                        <div
                          className="ion-no-padding ion-no-margin"
                          dangerouslySetInnerHTML={{
                            __html: customerProfile.setting[0].settings_value,
                          }}
                        ></div>
                      </IonItem>
                    )}
                  </IonCard>
                )}
              </IonCardContent>
            </IonCard>
            <IonCard className="ion-no-padding">
              <IonTitle className="ion-padding-start ion-no-margin">
                <strong>Upload Foto Pelanggan</strong>
              </IonTitle>
              <IonCardContent className="ion-no-padding ion-no-margin">
                <IonSegment
                  scrollable={true}
                  value={segmentImage}
                  className="ion-no-padding ion-no-margin"
                >
                  <IonSegmentButton
                    value="customer_profile_identity_card_image"
                    onClick={() => {
                      setSegmentImage("customer_profile_identity_card_image");
                    }}
                  >
                    <IonLabel>KTP</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="customer_profile_image1"
                    onClick={() => {
                      setSegmentImage("customer_profile_image1");
                    }}
                  >
                    <IonLabel>Foto 1</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="customer_profile_image2"
                    onClick={() => {
                      setSegmentImage("customer_profile_image2");
                    }}
                  >
                    <IonLabel>Foto 2</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="customer_profile_image3"
                    onClick={() => {
                      setSegmentImage("customer_profile_image3");
                    }}
                  >
                    <IonLabel>Foto 3</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    value="customer_profile_image4"
                    onClick={() => {
                      setSegmentImage("customer_profile_image4");
                    }}
                  >
                    <IonLabel>Foto 4</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
                <IonImg
                  src={
                    customerProfile[segmentImage] ??
                    `assets/img/image_not_available.png`
                  }
                />
              </IonCardContent>
            </IonCard>
          </>
        ) : (
          <IonImg className="img-empty" src={"assets/img/search-empty.webp"} />
        )}
      </IonContent>
      {loaded && customerProfile.customer_profile_status !== 3 && (
        <IonFab horizontal="end" vertical="bottom">
          <IonFabButton className="fab-up" size="small">
            <IonIcon size="small" icon={menuSharp} />
          </IonFabButton>
          <IonFabList side="top">
            {customerProfile.customer_profile_status === 1 &&
              getRole?.role_menu_edit === 1 &&
              getRole?.role_menu_add === 1 && (
                <IonFabButton
                  data-desc={`${t("edit")}`}
                  onClick={() => {
                    presentForm();
                  }}
                >
                  <IonIcon icon={createOutline} />
                </IonFabButton>
              )}
            {customerProfile.customer_profile_status === 1 &&
              getRole?.role_menu_edit === 1 &&
              getRole?.role_menu_add === 1 &&
              getRole?.role_menu_delete === 1 && (
                <IonFabButton
                  data-desc={`Proses ${t("check")}`}
                  onClick={() => {
                    processAlert(2, "check");
                  }}
                >
                  <IonIcon icon={checkmarkCircleOutline} />
                </IonFabButton>
              )}

            {customerProfile.customer_profile_status === 2 &&
              getRole?.role_menu_edit === 1 &&
              getRole?.role_menu_add === 1 &&
              getRole?.role_menu_delete === 1 &&
              getRole?.role_menu_print === 1 && (
                <IonFabButton
                  data-desc={`Proses ${t("approve")}`}
                  onClick={() => {
                    processAlert(3, "approve");
                  }}
                >
                  <IonIcon icon={checkmarkCircleOutline} />
                </IonFabButton>
              )}
          </IonFabList>
        </IonFab>
      )}
    </IonPage>
  );
};

export default Detail;
