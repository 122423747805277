import {
    IonPage,
    IonContent,
    IonSkeletonText,
    IonIcon,
    IonLabel,
    IonCard,
    IonItem,
    IonList,
    IonListHeader,
    IonThumbnail,
    IonImg,
    useIonAlert,
    IonCardContent,
    IonText,
    IonHeader,
    IonToolbar,
    IonButton,
    IonBadge,
    IonRow,
    IonCol,
    useIonModal,
    useIonLoading,
    useIonToast,
} from '@ionic/react';
import React, { useState} from 'react';
import { getNameDate, getRoleMenu } from '../../services/Library';
import { arrowBackOutline,  chatboxEllipsesOutline,  closeCircle,  documentTextOutline, imageOutline, logIn, logOut } from 'ionicons/icons';
import { RestApi } from '../../services/Api';
import { Customer } from '../../models/Modal'
import { Preferences } from '@capacitor/preferences';
import { useSelector } from 'react-redux';
import { loadList } from '../../models/Data';
import { useTranslation } from 'react-i18next';
import './History.scss';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

const ModalVisitImage: React.FC<any> = (props) => {
  const [baseUrl, setBaseUrl] = useState<any>("");
  const user = useSelector((state: any) => state.user.value);

  useConstructor(async () => {
    const url = await Preferences.get({ key: "url" });
    setBaseUrl(url.value);
  });

  return (
    <IonPage>
      <IonContent>
        <IonHeader>
          <IonToolbar class="toolbar-detail-visit ion-no-padding">
            <IonButton
              color="light"
              slot="start"
              fill="clear"
              onClick={() => {
                props.onDismissDetail();
              }}
            >
              <IonIcon color="light" slot="start" src={arrowBackOutline} />
            </IonButton>
            <IonItem className="ion-no-padding" lines="none">
              <IonLabel slot="start" className="ion-no-padding" color="light">
                <h4>
                  <strong>
                    {props.visit.customer_name} ({props.visit.customer_id})
                    <br />
                  </strong>
                </h4>
              </IonLabel>
              <IonLabel className="ion-no-padding" color="light">
                <span>{getNameDate(props.visit.visit_date)}</span>
              </IonLabel>
            </IonItem>
          </IonToolbar>
        </IonHeader>
        <IonCard>
          {props.visit.visit_image.length && (
            <IonRow>
              {props.visit.visit_image.map((value: any, key: any) => (
                <IonCol size="12" key={key}>
                  <IonImg
                    src={
                      baseUrl +
                      "/image/visit/" +
                      user.company_id +
                      "/" +
                      value.visitdet_image_path
                    }
                  />
                </IonCol>
              ))}
            </IonRow>
          )}
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

const History: React.FC<Customer> = (props) =>{
  const user = useSelector((state: any) => state.user.value);
  const [presentLoading, dismissLoading] = useIonLoading();
  const [alert] = useIonAlert();
  const [presentToast] = useIonToast();
  const { t } = useTranslation(["general"]);
  const [listHistory, setListHistory] = useState<any>([]);
  const [visit, setVisit] = useState<any>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const getRole = getRoleMenu(user.role_menu, "visit");

  const handleDismissVisitImage = (e: any) => {
    dismissVisitImage();
  };

  const [presentVisitImage, dismissVisitImage] = useIonModal(
    ModalVisitImage,
    {
      visit: visit,
      onDismissDetail: handleDismissVisitImage,
    }
  );

  const massageAlert = async (status:string,body:string) => {
    alert({
      header: t(status),
      message: body,
      cssClass: `header-alert-${status}`,
      buttons: [{ text: 'Ok', handler: (d:any) => {} }]
    })
  }

  const getHistoryList = async (page:string,next:boolean) => {
      await RestApi(`${page}`,'get',{},'application/json')
      .then((res:any) => {
          if (res.data.code === 200) {
              setListHistory(res.data.data)
              setLoaded(true)
          }else{
              props.onDismissDetail()
              massageAlert('error',res.data.message)
          }
      })
      .catch((err:any) => {
          props.onDismissDetail()
          massageAlert('error',err.message)
      });
  }

  const dateToHour = (arr:any) =>{
      let date = new Date(arr);
      return (
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
  }

  const returnElapsedTime = (epoch:number) => {
    var hours = epoch / 3600,
    minutes = (hours % 1) * 60,
    seconds = (minutes % 1) * 60;
    return (Math.floor(hours)? Math.floor(hours)+' Jam ' : '' )+ (Math.floor(minutes)? Math.floor(minutes)+' Menit ' : '' )+ Math.round(seconds) +' Detik';
  }

  const forceCheckout = (visit:any) => {
    alert({
      header: `Anda yakin Paksa Checkout? - ${visit.visit_id}`,
      message: "Masukan Catatan",
      cssClass: "header-alert",
      buttons: [
        {
          text: "Cancel",
          cssClass: "alert-button-cancel",
          role: "cancel",
        },
        {
          text: "OK",
          cssClass: "alert-button-confirm",
          role: "confirm",
          handler: async (data) => {
            if (data.note.length < 10) {
              presentToast({
                message: "Catatan Minimal 10 Karakter",
                duration: 1500,
                color: "danger",
                icon: closeCircle,
              });
              return;
            }else{
              await presentLoading({ message: t('loading') });
              await RestApi(`visit/update-note/${visit.visit_id}`, "post", {
                custid: visit.customer_id,
                visnote: `${visit.visitdet_note} [FORCE-CHECKOUT-NOTE] = ${data.note}`,
                visseq: 1,
              })
                .then(async (res: any) => {
                  if (res.data.code !== 200) {
                    try {
                      let messageObj = JSON.parse(res.data.message);
                      let message = "";
                      for (var key in messageObj) {
                        // skip loop if the property is from prototype
                        if (!messageObj.hasOwnProperty(key)) continue;
                        var obj = messageObj[key];
                        for (var prop in obj) {
                          // skip loop if the property is from prototype
                          if (!obj.hasOwnProperty(prop)) continue;
                          // your code
                          message += key + " = " + obj[prop];
                        }
                      }
                      massageAlert('error', message);
                    } catch (error) {
                      massageAlert('error', res.data.message);
                    }
                  }
                })
                .catch((error: any) => {
                  massageAlert('error', error.message);
                });

              await RestApi(`visit/${visit.visit_id}`, "post", {
                vislat: visit.customer_lat,
                vislng: visit.customer_lng,
                custid: visit.customer_id,
                vistype: 1,
              })
              .then(async (res: any) => {
                if (res.data.code === 200) {
                  getHistoryList(
                    `visit/history-visit/${props.idCustomer}`,
                    false
                  );
                  massageAlert('succes', res.data.message);
                  dismissLoading();
                } else {
                  massageAlert('error', res.data.message);
                  dismissLoading();
                }
              })
              .catch((error: any) => {
                massageAlert('error', error.message);
                dismissLoading();
              });
            }
          },
        },
      ],
      inputs: [
        {
          placeholder: "Catatan",
          type: "textarea",
          value: "",
          name: "note",
        },
      ],
    });
  };
  
  useConstructor(() => {
    getHistoryList(`visit/history-visit/${props.idCustomer}`,false)
  });

  return (
    <IonPage>
      <IonHeader className="toolbar-history">
        {loaded ? (
          <IonToolbar className="ion-no-padding">
            <IonButton
              color="light"
              slot="start"
              fill="clear"
              onClick={() => props.onDismissDetail()}
            >
              <IonIcon color="light" slot="start" src={arrowBackOutline} />
            </IonButton>
            <IonLabel
              color="light"
              className="ion-text-center ion-no-padding"
            >
              <h1>List History</h1>
              <h4>
                <strong>
                  {listHistory[0].customer_name} ({listHistory[0].customer_id}
                  )
                </strong>
              </h4>
            </IonLabel>
            <IonLabel slot="end" className="ion-padding">
              <IonBadge color="light" slot="end">
                {listHistory.length}
              </IonBadge>
            </IonLabel>
          </IonToolbar>
        ) : (
          <IonToolbar>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: "100%" }}
            ></IonSkeletonText>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent className="content-history">
        {!loaded && (
          <IonList>
            <IonListHeader>
              <IonSkeletonText
                animated={true}
                style={{ width: "80px" }}
              ></IonSkeletonText>
            </IonListHeader>
            {loadList.map((value: any, key: any) => (
              <IonItem key={key}>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "30%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        )}
        {listHistory.length && loaded ? (
          <IonList className="main-card">
            {listHistory.map((value: any, key: any) => (
              <IonCard className="ion-padding" key={key} onClick={() => {
                  if (
                    !value.checkoutat &&
                    value.checkinat &&
                    getRole?.role_menu_edit && 
                    getRole?.role_menu_delete
                  ) {
                    forceCheckout(value);
                  }
                }}>
                <IonItem className="ion-no-padding">
                  <IonLabel className="ion-no-padding">
                    <span>{getNameDate(value.visit_date)}</span>
                    <h2>
                      <strong>
                        {value.visit_id}
                        {value.visit_image.length && (
                          <IonIcon
                            size="medium"
                            color="primary"
                            icon={imageOutline}
                            onClick={() => {
                              setVisit(value);
                              presentVisitImage();
                            }}
                          ></IonIcon>
                        )}
                      </strong>
                    </h2>
                    <p>
                      <IonBadge>
                        {returnElapsedTime(value.visitdet_duration)}
                      </IonBadge>
                    </p>
                  </IonLabel>
                  <IonLabel
                    slot="end"
                    className="ion-text-end ion-no-padding"
                  >
                    <IonBadge color="dark">
                      <span>{value.visit_salesman_name}</span>
                    </IonBadge>
                    <br />
                    <IonIcon
                      color="danger"
                      className="ion-no-padding"
                      src={logIn}
                    />
                    {value.checkinat ? dateToHour(value.checkinat) : "-"}{" "}
                    <br />
                    <IonIcon
                      color="success"
                      className="ion-no-padding"
                      src={logOut}
                    />
                    {value.checkoutat ? dateToHour(value.checkoutat) : "-"}
                  </IonLabel>
                </IonItem>
                <IonCardContent className="ion-no-padding">
                  <IonItem className="ion-no-padding">
                    <IonText>
                      <h4>
                        <strong>Keterangan</strong>
                        <IonIcon color="primary" icon={documentTextOutline} />
                      </h4>
                      <p>{value.visitdet_remark ?? "-"}</p>
                    </IonText>
                  </IonItem>
                  <IonItem className="ion-no-padding">
                    <IonText>
                      <h4>
                        <strong>Catatan</strong>
                        <IonIcon
                          color="primary"
                          icon={chatboxEllipsesOutline}
                        />
                      </h4>
                      <p>{value.visitdet_note ?? "-"}</p>
                    </IonText>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            ))}
          </IonList>
        ) : (
          <IonImg
            className="img-empty"
            src={"assets/img/search-empty.webp"}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default History;
